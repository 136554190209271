import React from "react";
import Play1 from "../assets/images/play1.svg";
import Play2 from "../assets/images/play2.svg";
import Play3 from "../assets/images/play3.svg";

export default function HowToPlay() {
  return (
    <React.Fragment>
      <section className="w-full container mx-auto flex flex-col items-center justify-center">
        <h2 className="text-[#3E008C] font-semibold text-7xl leading-tight cursor-default mt-24">Let's begin!</h2>
        <p className="text-[#5A606D] mt-4 cursor-default">Download HotQA now</p>

        <img src={Play1} alt="How to play" className="object-contain" />
        <img src={Play2} alt="How to play" className="object-contain" />
        <img src={Play3} alt="How to play" className="object-contain" />
      </section>
    </React.Fragment>
  );
}
