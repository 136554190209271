import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Layout from "./layout/layout";
import NoMatch from "./layout/no-match";
import HomePage from "./pages/home";
import HowToPlay from "./pages/how-to-play";
import Whitepaper from "./pages/white-paper";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy";
import TermCondition from "./pages/terms";
import Challenge from "./pages/challenge";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<HomePage />} />
      <Route path="how-to-play" element={<HowToPlay />} />
      <Route path="whitepaper" element={<Whitepaper />} />
      <Route path="contact" element={<Contact />} />
      <Route path="challenge" element={<Challenge />} />
      <Route path="privacy.html" element={<PrivacyPolicy />} />
      <Route path="terms.html" element={<TermCondition />} />

      {/* <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="dashboard" element={<Dashboard />} /> */}

      {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
