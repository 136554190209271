import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import mascot1 from "../assets/images/mascot1.png";
import trending1 from "../assets/images/trending1.svg";
import trending2 from "../assets/images/trending2.svg";
import trending3 from "../assets/images/trending3.svg";
import img_offer from "../assets/images/img_offer.png";
import ring_1 from "../assets/images/ring_1.png";
import ball from "../assets/images/ball.svg";
import QRCode from "react-qr-code";

export default function Challenge() {
  const [faqActive, setFaqActive] = useState<number>(-1);
  const trending = [
    {
      img: trending1,
      name: "Learn-to-Earn quizzes",
      des: "Create a series of educational quizzes about your project to bring the most effective marketing outcomes with optimal cost.",
    },
    {
      img: trending2,
      name: "Multiple game modes",
      des: "Freely design campaigns with a variety of game modes from PvP, PvG or Tournament to diversify and arouse participants.",
    },
    {
      img: trending3,
      name: "Expand your community",
      des: "Build your own space/community with our exclusive tools and features and take advantage of the user base from HotQA as well as our media partners.",
    },
  ];

  const offer = [
    {
      name: "Zero-fee Learn-to-Earn campaigns",
      des: "Build your first Learn-to-Earn campaign on HotQA with completely no charge.*",
    },
    {
      name: "Reach out to 1M+ followers",
      des: "Maximize brand awareness through our massive global user base from HotQA and our media partners.",
    },
    {
      name: "Advanced marketing approach",
      des: "Reward users through fascinating educational series about your project.",
    },
  ];

  const faq = [
    {
      name: '1. How many "Challenges" campaigns can we host through HotQA?',
      des: "HotQA “Challenges” is totally free. All projects can build your own community and host as many “Challenges” as you like.",
    },
    {
      name: "2. What is the limited duration of the “Challenges” campaign?",
      des: "HotQA “Challenges” support all the duration your campaign might need. We recommend your campaigns should last for 7-14 days for the best outcomes.",
    },
    {
      name: "3. How many reward methods are available in HotQA “Challenges”?",
      des: "There are 2 reward methods developed by HotQA including Lucky Draw and FCFS. For the first one, HotQA will randomly select among qualified participants to select lucky winners as soon as the event times out. If the project uses the FCFS method, the first qualified participants will automatically become winners. Once the project's predetermined number of winners has been met, the campaign will be wrapped up.",
    },
    {
      name: "4. Which rewarding currency is supported on HotQA’s platform?",
      des: "We support all coins/tokens of the BNB Smart Chain network.",
    },
  ];

  return (
    <section className="w-full container mx-auto relative z-10">
      {/* <div className="flex justify-end my-8">
        <img src={logo} className="w-[170px]" alt="logo" />
      </div> */}

      <div className="flex flex-row mt-10">
        <div className="px-10 max-w-full relative z-10">
          <p
            className="text-[#363638] font-black text-[5.75em] leading-none"
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            JOIN HOTQA
          </p>
          <div className="relative inline-block">
            <p className="absolute h-[86px] left-[-36px] right-[-20px] bg-[#A4FFD8] bg-opacity-80 transform -rotate-[1.23deg] top-3" />
            <p
              className="text-[#363638] font-black text-[5.75em] leading-none z-10 relative"
              style={{
                fontFamily: "Darker Grotesque",
                marginLeft: "-38px",
              }}
            >
              "CHALLENGES"
            </p>
          </div>
          <p className="text-[#363638] font-bold text-[1.75em] leading-none my-6">Incentivized Learn-to-Earn Program</p>
          <p className="text-[#6C6C70] text-xl leading-normal my-4 max-w-[500px] font-normal">
            Incentivize your audience and Boost your brand awareness with our zero-fee Learn-to-Earn campaigns
          </p>
          <div className="flex flex-row gap-2">
            <div>
              <a
                href="https://bit.ly/HotQA-Challenges"
                target={"_blank"}
                className={
                  "bg-[#662DDF] text-[#EBEBF0] inline-flex flex-row items-center justify-center text-xl font-semibold rounded-full px-6 py-2 hover:py-[10px] hover:bg-[#00C58D] transition-all duration-300 ease-in-out"
                }
                rel="noreferrer"
              >
                <span className="text-[#EBEBF0] text-xl font-semibold pr-2 inline-block">{"APPLY NOW"}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_804_30493)">
                    <path
                      d="M13.2969 4.14062L21.5469 12.0156C21.7812 12.25 21.9219 12.5312 21.9219 12.8594C21.9219 13.1406 21.7812 13.4219 21.5469 13.6562L13.2969 21.5312C12.875 21.9531 12.125 21.9531 11.7031 21.4844C11.2812 21.0625 11.2812 20.3125 11.75 19.8906L17.9844 13.9844H2.04688C1.39062 13.9844 0.921875 13.4688 0.921875 12.8594C0.921875 12.2031 1.39062 11.7344 2.04688 11.7344H17.9844L11.75 5.78125C11.2812 5.35938 11.2812 4.60938 11.7031 4.1875C12.125 3.71875 12.8281 3.71875 13.2969 4.14062Z"
                      fill="#EBEBF0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_804_30493">
                      <rect width="24" height="24" fill="white" transform="translate(0.125 0.8125)" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>

            <div className="bg-white">
              <QRCode
                size={100}
                style={{ height: "auto", maxWidth: "100px", width: "100%", padding:8 }}
                value={"https://bit.ly/HotQA-Challenges"}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>

        <img src={mascot1} className="max-w-[708px] w-full -ml-[120px] -mr-[90px] object-contain" alt="mascot1" />
      </div>

      <div className="flex flex-col mt-20">
        <div className="flex flex-col items-center">
          <p
            className="text-[#242426] font-black text-[55px] text-center inline-block mx-auto"
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            LEAD THE TREND
          </p>
        </div>

        <div className="grid grid-cols-3 gap-8 mt-5">
          {trending.map((i) => {
            return (
              <div className="relative group" key={i.name}>
                <div className="item-trending p-[3px] flex absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-500">
                  <div className="bg-[#F2F2F2] rounded-2xl flex-1"></div>
                </div>
                <div className="relative rounded-2xl z-10 p-7">
                  <div className="flex flex-col items-center pt-11 pb-12">
                    <img src={i.img} alt={i.name} className="w-[111px] h-[111px]" />
                    <p
                      className="text-center font-extrabold mt-4 text-4xl max-w-[80%]"
                      style={{
                        fontFamily: "Darker Grotesque",
                      }}
                    >
                      {i.name}
                    </p>
                    <p className="text-center text-[#6C6C70] mt-4">{i.des}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col mt-20">
        <div className="flex flex-col items-center">
          <p
            className="text-[#242426] font-black text-[55px] text-center inline-block mx-auto"
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            WHAT WE OFFER
          </p>
        </div>

        <div className="flex flex-row items-center">
          <img src={img_offer} alt="offer" className="w-[689.37px] object-contain" />
          <div className="flex flex-col items-center justify-center">
            {offer.map((i) => {
              return (
                <div key={i.name} className="relative pt-[8px] pl-[20px] mt-8">
                  <img src={ring_1} alt="ring" className="w-[38px] h-[38px] absolute top-0 left-0" />
                  <label className="text-black font-extrabold text-[34px] leading-tight">{i.name}</label>
                  <p className="text-[#6C6C70] mt-3">{i.des}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center justify-center mt-6">
          <a
            href="https://bit.ly/HotQA-Challenges"
            target={"_blank"}
            className={
              "bg-[#662DDF] text-[#EBEBF0] inline-flex flex-row items-center justify-center text-xl font-semibold rounded-full px-6 py-2 hover:py-[10px] hover:bg-[#00C58D] transition-all duration-300 ease-in-out"
            }
            rel="noreferrer"
          >
            <span className="text-[#EBEBF0] text-xl font-semibold pr-2 inline-block">{"Host your Challenge"}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_804_30493)">
                <path
                  d="M13.2969 4.14062L21.5469 12.0156C21.7812 12.25 21.9219 12.5312 21.9219 12.8594C21.9219 13.1406 21.7812 13.4219 21.5469 13.6562L13.2969 21.5312C12.875 21.9531 12.125 21.9531 11.7031 21.4844C11.2812 21.0625 11.2812 20.3125 11.75 19.8906L17.9844 13.9844H2.04688C1.39062 13.9844 0.921875 13.4688 0.921875 12.8594C0.921875 12.2031 1.39062 11.7344 2.04688 11.7344H17.9844L11.75 5.78125C11.2812 5.35938 11.2812 4.60938 11.7031 4.1875C12.125 3.71875 12.8281 3.71875 13.2969 4.14062Z"
                  fill="#EBEBF0"
                />
              </g>
              <defs>
                <clipPath id="clip0_804_30493">
                  <rect width="24" height="24" fill="white" transform="translate(0.125 0.8125)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>

      <div className="flex flex-col mt-20">
        <div className="flex flex-col items-center">
          <p
            className="text-[#242426] font-black text-[55px] text-center inline-block mx-auto"
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            FAQ
          </p>
        </div>

        <div className="">
          {faq.map((i, index) => {
            return (
              <div
                key={`${i.name}`}
                className={`group relative bg-white rounded-[10px] mt-4 px-4 py-3 hover:bg-opacity-100 transition-all cursor-pointer duration-500 ${
                  faqActive === index ? "opacity-100" : "bg-opacity-0"
                }`}
                onClick={() => {
                  if (faqActive === index) {
                    setFaqActive(-1);
                  } else {
                    setFaqActive(index);
                  }
                }}
              >
                <img
                  src={ball}
                  alt="ball"
                  className={`w-[21px] h-[21px] absolute top-[22px] left-[16px] opacity-0 group-hover:opacity-100 transition-all duration-500 ${
                    faqActive === index ? "opacity-100" : ""
                  }`}
                />
                <div
                  className={`relative group-hover:pl-8 transition-all duration-500 ${
                    faqActive === index ? "pl-8" : "pl-0"
                  }`}
                >
                  <p className="text-[#6C6C70] font-semibold text-[28px]">{i.name}</p>

                  <div
                    className={`transition-all duration-700 overflow-hidden ${
                      faqActive !== index ? "max-h-0" : "max-h-52"
                    }`}
                  >
                    <p className={`text-[#8E8E93] pt-4 transition-all duration-700 overflow-hidden`}>{i.des}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col mt-20">
        <div className="flex flex-col items-center">
          <p
            className="text-[#242426] font-black text-[55px] text-center inline-block mx-auto max-w-[1031px]"
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            BUILD YOUR FIRST LEARN-TO-EARN CAMPAIGN WITH HOTQA
          </p>
        </div>

        <div className="flex items-center justify-center mt-6">
          <a
            href="https://bit.ly/HotQA-Challenges"
            target={"_blank"}
            className={
              "bg-[#662DDF] text-[#EBEBF0] inline-flex flex-row items-center justify-center text-xl font-semibold rounded-full px-6 py-2 hover:py-[10px] hover:bg-[#00C58D] transition-all duration-300 ease-in-out"
            }
            rel="noreferrer"
          >
            <span className="text-[#EBEBF0] text-xl font-semibold pr-2 inline-block">{"JOIN NOW"}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_804_30493)">
                <path
                  d="M13.2969 4.14062L21.5469 12.0156C21.7812 12.25 21.9219 12.5312 21.9219 12.8594C21.9219 13.1406 21.7812 13.4219 21.5469 13.6562L13.2969 21.5312C12.875 21.9531 12.125 21.9531 11.7031 21.4844C11.2812 21.0625 11.2812 20.3125 11.75 19.8906L17.9844 13.9844H2.04688C1.39062 13.9844 0.921875 13.4688 0.921875 12.8594C0.921875 12.2031 1.39062 11.7344 2.04688 11.7344H17.9844L11.75 5.78125C11.2812 5.35938 11.2812 4.60938 11.7031 4.1875C12.125 3.71875 12.8281 3.71875 13.2969 4.14062Z"
                  fill="#EBEBF0"
                />
              </g>
              <defs>
                <clipPath id="clip0_804_30493">
                  <rect width="24" height="24" fill="white" transform="translate(0.125 0.8125)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}
