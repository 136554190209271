import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo-white.png";
import bg from "../assets/images/bg-footer-top.svg";
import GooglePlay from "../assets/images/google-play.svg";
import AppStore from "../assets/images/app-store.svg";
import Facebook from "../assets/images/facebook.svg";
import Twitter from "../assets/images/twitter.png";
import Telegram from "../assets/images/telegram.svg";
import Discord from "../assets/images/discord.svg";
import Tiktok from "../assets/images/tiktok.png";
import Linkedin from "../assets/images/linkedin.svg";

export default function FooterLayout() {
  const menu1 = [
    { label: "Whitepaper", to: "#", link: "https://hotqa.gitbook.io/hotqa-whitepaper/" },
    { label: "News", to: "#", link: "https://hub.hotqa.io/" },
    // { label: "Media Kit", to: "#" },
    { label: "Privacy & Policy", to: "/privacy.html" },
    { label: "Term & Condition", to: "/terms.html" },
  ];
  const menu2 = [
    { label: "Affiliate", to: "#", link: undefined },
    { label: "Friend Referrals", to: "#" },
  ];
  const menu3 = [
    { label: "/HotQA.Official", to: "#", icon: Facebook, link: "https://www.facebook.com/HotQA" },
    { label: "/HotQA_Official", to: "#", icon: Twitter, link: "https://twitter.com/HotQA_Official" },
    { label: "@HotQA_Official", to: "#", icon: Telegram, link: "https://t.me/HotQA" },
    { label: "discord", to: "#", icon: Discord, link: "https://discord.gg/tFCg6an6HQ" },
    { label: "company/hotqaofficial", to: "#", icon: Linkedin, link: "https://www.linkedin.com/company/hotqaq/" },
    // { label: "@hotqa_official", to: "#", icon: Tiktok, link: "https://www.tiktok.com/@hotqa_official" },
  ];

  return (
    <footer className="footer pt-10">
      <img src={bg} className="w-full" alt="bg" />
      <div className="bg-[#662DDF] w-full py-10">
        <div className="container mx-auto flex justify-between flex-wrap gap-4 px-4 lg:px-0">
          <div className="flex flex-col">
            <NavLink to={"/"}>
              <a href="/" className="logo block max-w-[142px] w-full">
                <img src={Logo} alt="hotqa" className="object-contain" />
              </a>
            </NavLink>

            <ul className="flex flex-row gap-x-4 mt-4">
              {menu3.map((item) => {
                return (
                  <li className="inline-flex flex-row">
                    <a
                      style={{ fontFamily: "Nunito" }}
                      className="inline-block"
                      href={item.link}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img src={item.icon} alt={item.label} className="w-6 h-6" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <nav className="inline-flex flex-col">
            <label className="text-white text-xl leading-7 font-extrabold" style={{ fontFamily: "Nunito" }}>
              About
            </label>
            <ul className="inline-flex flex-col space-y-4 mt-4">
              {menu1.map((item) => {
                if (item.link) {
                  return (
                    <li>
                      <a
                        style={{ fontFamily: "Nunito" }}
                        className="text-white font-semibold text-base leading-snug hover:underline"
                        href={item.link}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                }

                return (
                  <li>
                    <NavLink
                      style={{ fontFamily: "Nunito" }}
                      className="text-white font-semibold text-base leading-snug hover:underline"
                      to={item.to}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>

          <nav className="inline-flex flex-col">
            <label className="text-white text-xl leading-7 font-extrabold" style={{ fontFamily: "Nunito" }}>
              Program
            </label>
            <ul className="inline-flex flex-col space-y-4 mt-4">
              {menu2.map((item) => {
                if (item.link) {
                  return (
                    <li>
                      <a
                        style={{ fontFamily: "Nunito" }}
                        className="text-white font-semibold text-base leading-snug hover:underline"
                        href={item.link}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                }

                return (
                  <li>
                    <NavLink
                      style={{ fontFamily: "Nunito" }}
                      className="text-white font-semibold text-base leading-snug hover:underline"
                      to={item.to}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>

          {/* <nav className="inline-flex flex-col">
            <label className="text-white text-xl leading-7 font-extrabold" style={{ fontFamily: "Nunito" }}>
              Social Media
            </label>
            <ul className="inline-flex flex-col space-y-4 mt-4">
              {menu3.map((item) => {
                return (
                  <li className="inline-flex flex-row">
                    <img src={item.icon} alt={item.label} className="w-6 h-6" />
                    <a
                      style={{ fontFamily: "Nunito" }}
                      className="text-white font-semibold text-base leading-snug hover:underline ml-3"
                      href={item.link}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav> */}

          <div>
            <div className="flex flex-col justify-center items-center space-y-5">
              <a href="https://play.google.com/store/apps/details?id=com.hotqa.io" target="_black">
                <img src={GooglePlay} alt="Google Play" className="w-[170px] h-[54px] object-contain" />
              </a>
              <a href="itms-apps://itunes.apple.com/us/app/hotqa/id1610442730?mt=8" target="_black">
                <img src={AppStore} alt="App Store" className="w-[170px] h-[54px] object-contain" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
