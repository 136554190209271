import React from "react";
import ReactDOM from "react-dom";
import GooglePlay from "../assets/images/google-play.svg";
import AppStore from "../assets/images/app-store.svg";
import { sendEventGoogle } from "../google-tag";

export function PopupApp(props: { show: boolean; onClose: () => void; closeOutsize?: boolean }) {
  if (!props.show) {
    return null;
  }
  return ReactDOM.createPortal(
    <React.Fragment>
      <div className="fixed bg-black bg-opacity-30 top-0 left-0 w-full h-full" style={{ zIndex: 1055 }}></div>
      <div
        className="fixed top-0 left-0 w-full h-full z-50 overflow-y-auto overflow-x-hidden flex items-center justify-center "
        style={{ zIndex: 1055 }}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (props.closeOutsize) props.onClose();
        }}
      >
        <div
          className="bg-white rounded-[20px] inline-flex flex-col p-20 justify-center items-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div>
            <label className="text-[#662DDF] font-semibold text-3xl">Download HotQA App</label>
            <div className="flex flex-row justify-center items-center space-x-5 mt-6">
              <a
                href="https://play.google.com/store/apps/details?id=com.hotqa.io"
                target="_black"
                onClick={(e) => {
                  if (typeof window?.open === "function") {
                    window.open("https://play.google.com/store/apps/details?id=com.hotqa.io", "_black");
                  }
                  sendEventGoogle("Install App", { platform: "Android" });
                }}
              >
                <img src={GooglePlay} alt="Google Play" className="w-[180px] h-[54px] object-contain" />
              </a>

              <a
                href="itms-apps://itunes.apple.com/us/app/hotqa/id1610442730?mt=8"
                target="_black"
                onClick={() => {
                  if (typeof window?.open === "function") {
                    window.open("itms-apps://itunes.apple.com/us/app/hotqa/id1610442730?mt=8", "_black");
                  }
                  sendEventGoogle("Install App", { platform: "IOS" });
                }}
              >
                <img src={AppStore} alt="App Store" className="w-[180px] h-[54px] object-contain" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>,
    document.body
  );
}
