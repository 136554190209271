import univer1 from "./univer1.png";
import univer2 from "./univer2.png";
import ic_social from "./ic_social.png";
import ic_challenge from "./ic_challenge.png";
import ic_livestream from "./ic_livestream.png";
import ic_pvg from "./ic_pvg.png";
import ic_pvp from "./ic_pvp.png";
import challenge from "./challenge.png";
import live from "./live.png";
import pvg from "./pvg.png";
import pvp from "./pvp.png";
import social from "./social.png";
import bg from "./bg.png";

export const universe = {
  uni1: univer1,
  uni2: univer2,
  ic_social: ic_social,
  ic_challenge,
  ic_livestream,
  ic_pvg,
  ic_pvp,
  challenge,
  live,
  pvg,
  pvp,
  social,
  bg
};
