import React from "react";
import Linkedin from "../assets/images/linkedin-cover.png";

export default function Whitepaper() {
  return (
    <React.Fragment>
      <section className="w-full mx-auto">
        <img src={Linkedin} alt="linkedin cover" className="object-contain" />
      </section>
      <section className="w-full mx-auto container max-w-screen-md px-4">
        <h2 className="text-[#3E008C] font-semibold text-7xl leading-tight cursor-default mt-10">HotQA</h2>
        <p className="text-[#5A606D]">HotQA's Official Whitepaper, updated in December 2022</p>
        <p className="text-[#5A606D] mt-4">
          HotQA is a Play-to-learn, Answer-to-earn platform that anyone can join not only to earn but also to flex
          knowledge in an entertaining way. HotQA aims to be a community-driven platform empowering users by rewarding
          them for their engagement and enjoyment. HotQA is truly for everyone that offers a wide range of subject
          matter (from General to History, Music, Science, Blockchain, and so on), allows players across multiple
          platforms to play together, and develops multi-game mode to play anytime, anywhere.
        </p>
        <div className="text-[#5A606D] mt-4">
          <h4 className="font-bold text-[1.375em] mt-6 leading-5">Unique & Premier Selling Points</h4>
          <ul className="list-decimal pl-4 space-y-2 mt-4">
            <li>
              Easy Gameplay: The gameplay is easy as a piece of cake. Anyone can take part in the game without diving
              deep into the blockchain & crypto. Everyone with a phone or a PC/Laptop and an Internet connection could
              enjoy HotQA anytime.2.
            </li>
            <li>
              No Financial Barriers: Unlike NFT Games whose players are required to are required to invest a certain
              amount in NFTs in order to earn rewards from the game, HotQA is open to everyone with no financial
              requirements.s3.
            </li>
            <li>
              Innovative In-Game Tokens: HotQA accepts stablecoins and others in our first phase. Then HQA is the
              innovative token that empowers the HotQA ecosystem with a revolutionary buyback mechanism and various use
              cases. HotQA tokenomic will not be like games that adopt the morphing GameFi model have turned into Ponzi,
              and like all other financial-related fields.s4.
            </li>
            <li>
              Play-To-Learn & Answer-to-Earn HotQA combines many models, Live Game Show is centered, though, Battle
              (PvP, PvG) and Daily Challenge are being developed. They all are characterized by multiple-choice quizzes
              about thousands of topics. So playing with quizzes is useful for memorizing and recalling facts, quickly
              assessing knowledge, or getting quick info at the end of a presentation. And HotQA provides a pretty fun
              way to have a chance to win prizes when answering.5.
            </li>
            <li>
              All-in-one App/Web: HotQA is also developing an AMA Platform, Socials, and building Media Channels that
              help not only blockchain-based projects but also provide a fresh perspective on Blockchain & Crypto for
              anyone who wants to learn about these industries. All are integrated into one App/Web.6.
            </li>
            <li>
              The First Metaverse for Live Trivia Game Shows, HotQA will be a blockchain-based decentralized metaverse
              specialized for live trivia game shows that enables non-tech savvy users to Create - Own - Govern - Host
              game shows in their own concepts at their own virtual pieces of LAND. Audiences can visit themed areas,
              attend events of their interest and hence have fun and knowledge, and get prizes.
            </li>
          </ul>
        </div>
        <div className="text-[#5A606D] mt-4">
          <h4 className="font-bold text-[1.375em] mt-6 leading-5">Mission</h4>
          <p className="mt-4">
            The core mission of the HotQA is to build a knowledge-based & decentralized gamified platform that bridges
            the gap between the crypto-verse & the traditional world.
          </p>
        </div>
        <div className="text-[#5A606D] mt-4">
          <h4 className="font-bold text-[1.375em] mt-6 leading-5">Vision</h4>
          <p className="mt-4">
          HotQA's vision is to become the Universe of Knowledge and the biggest fully decentralized virtual world specialized for live trivia game shows where anyone is given creative freedom. We aim to turn players into creators, push for a player-owned economy and bring the key to unlock crypto mass adoption.
          </p>
        </div>
        <div className="bg-white rounded-2xl p-6 mt-4">
            <p className="text-[#5A606D] italic">Disclaimer: Please note that anything written in this white paper should not be taken as financial advice. HotQA is a bleeding-edge game that's incorporating unfinished, risky, and highly experimental technology. Development priorities, roadmaps, and features are subject to radical overhaul based on research, traction, feedback from the community, and a myriad of other factors.</p>
        </div>
      </section>
    </React.Fragment>
  );
}
