import React from "react";
import "./animated.css";
import { universe } from "../assets/images/animate/universe";

const dataDefault = {
  pvp: {
    label: "PvP",
    icon: universe.ic_pvp,
    img: universe.pvp,
  },
  pvg: {
    label: "PvG",
    icon: universe.ic_pvg,
    img: universe.pvg,
  },
  challenge: {
    label: "Challenge",
    icon: universe.ic_challenge,
    img: universe.challenge,
  },
  live: {
    label: "Livestream",
    icon: universe.ic_livestream,
    img: universe.live,
  },
  socials: {
    label: "Socials",
    icon: universe.ic_social,
    img: universe.social,
  },
};

export default function Animated() {
  const [step, setStep] = React.useState<boolean>(false);

  const [data, setData] = React.useState<any>({
    "1": dataDefault.pvp,
    "2": dataDefault.pvg,
    "3": dataDefault.live,
    "4": dataDefault.socials,
    active: dataDefault.challenge,
  });

  const [action, setAction] = React.useState<string>("action1");

  const handleChange = (val: string) => {
    const ac = data.active;
    const active = data[val];
    setStep(!step);
    setAction(`action${val}`);
    setData({
      ...data,
      [val]: ac,
      active,
    });
  };

  return (
    <div className={`min-w-[992px] md:min-w-[1px]  w-[904px] h-[516px] flex items-center justify-center relative ${step ? "step1" : "step2"}`}>
      <img className="uni uni-size ani" src={universe.uni1} alt="universe" />
      <img className="bg" src={universe.bg} alt={"background"} />

      <div id="main" className={action}>
        <label>{data["active"].label}</label>
        <img src={data["active"].img} alt={data["active"].label} />
      </div>

      <div
        onClick={() => {
          handleChange("1");
        }}
        className="box_ic ic_1"
      >
        <label>{data["1"].label}</label>
        <img src={data["1"].icon} alt={data["1"].label} />
      </div>
      <div
        onClick={() => {
          handleChange("2");
        }}
        className="box_ic ic_2"
      >
        <img src={data["2"].icon} alt={data["2"].label} />
        <label>{data["2"].label}</label>
      </div>
      <div
        onClick={() => {
          handleChange("3");
        }}
        className="box_ic ic_3"
      >
        <label>{data["3"].label}</label>
        <img src={data["3"].icon} alt={data["3"].label} />
      </div>
      <div
        onClick={() => {
          handleChange("4");
        }}
        className="box_ic ic_4"
      >
        <img src={data["4"].icon} alt={data["4"].label} />
        <label>{data["4"].label}</label>
      </div>
    </div>
  );
}
