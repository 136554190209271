import React from "react";
import { Outlet } from "react-router-dom";
import HeaderLayout from "./header";
import FooterLayout from "./footer";

export default function Layout() {
  return (
    <React.Fragment>
      {/* A "layout route" is a good place to put markup you want to
           share across all the pages on your site, like navigation. */}
      <HeaderLayout />

      {/* An <Outlet> renders whatever child route is currently active,
           so you can think about this <Outlet> as a placeholder for
           the child routes we defined above. */}
      <main className="pt-[84px] bg-container">
        <Outlet />
        <FooterLayout />
      </main>
    </React.Fragment>
  );
}
