import i1 from "./blckchn.png";
import i2 from "./Oxalus.png";
import i3 from "./ace_starter.svg";
import i4 from "./4.png";
import i5 from "./5.png";
import i6 from "./6.png";
import i7 from "./7.png";
import i8 from "./8.png";
import i9 from "./9.png";
import i10 from "./10.png";

export const PartnersImg = [i1,i2, i3, i4, i5, i6, i7, i8, i9, i10];
