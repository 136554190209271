import React from "react";
import ContactImg from "../assets/images/contact-img.png";

export default function Contact() {
  const menu1 = [
    "Device & Account",
    "Challenges",
    "PvP & PvG",
    "Livestreamed Trivia",
    "Socials",
    "Wallets",
    "Programs",
    "Others",
  ];

  const menu2 = [
    "Become our Partner",
    "Create your Challenge",
    "Create your group on Socials",
    "Create your livestreamed trivia",
  ];

  return (
    <React.Fragment>
      <section className="w-full container mx-auto py-24 overflow-hidden">
        <div className="lg:-mx-4">
          <div className="px-4 w-full lg:w-3/4 float-left">
            <h2 className="text-[#3E008C] font-semibold text-7xl leading-tight cursor-default">Contact</h2>
            <div className="w-full mt-11">
              <label className="text-[#434343] font-extrabold text-xl">FAQ</label>
              <ul className="w-full border-t border-t-[#28F29C] mt-4 pt-4 gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {menu1.map((i) => {
                  return (
                    <li className="col-span-1">
                      <a href="#" aria-current="page" className="text-[#434343] hover:underline">
                        {i}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full mt-16">
              <label className="text-[#434343] font-extrabold text-xl">Forms & Resources</label>
              <ul className="w-full border-t border-t-[#28F29C] mt-4 pt-4 gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {menu2.map((i) => {
                  return (
                    <li className="col-span-1">
                      <a href="#" aria-current="page" className="text-[#434343] hover:underline">
                        {i}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="px-4 hidden w-1/4 lg:flex float-right justify-center items-center">
            <img src={ContactImg} alt="contact" className="float-right" />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
