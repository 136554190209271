import React, { useEffect, useState } from "react";
import PlayToLearn from "../assets/images/play-to-learn.svg";
import PlayToLearnMobile from "../assets/images/play-mobile.svg";
import PlayToLearn2 from "../assets/images/play-to-learn22.svg";
import BGGalaxy from "../assets/images/bg-galaxy.png";
import GooglePlay from "../assets/images/google-play.svg";
import AppStore from "../assets/images/app-store.svg";
import ImgChallenge from "../assets/images/img-challenge.png";
import Coin from "../assets/images/coin.svg";
import Banner1 from "../assets/images/VBS_Logo.png";
import Banner2 from "../assets/images/Grey_Playful_Banner.png";
import Banner3 from "../assets/images/VBS_Logo_2.png";
import { PartnersImg } from "../assets/images/partners";
import { ButtonPopupApp } from "../component/button-popup-app";
import Animated from "../component/animated";
import { PopupApp } from "../component/popup-app";
import { Link, NavLink } from "react-router-dom";
import { sendEventGoogle } from "../google-tag";

// https://api-staging.hotqa.io/open-challenges
// https://hub.hotqa.io/wp-json/wp/v2/posts?page=$%7Bpage%7D&per_page=$%7Bper_page%7D&categories=$%7Bcategory%7D
export default function HomePage() {
  // const itemGalaxy = [
  //   { label: "Daily Challenges", thumb: GalaxyImage.img1 },
  //   { label: "PvP & PvG", thumb: GalaxyImage.img2 },
  //   { label: "Livestreamed Trivia", thumb: GalaxyImage.img3 },
  //   { label: "Socials", thumb: GalaxyImage.img4 },
  //   { label: "Socials", thumb: GalaxyImage.img5 },
  // ];

  const datGroup = [
    {
      name: "Vietnam Blockchain Summit",
      image: Banner1,
      des: "Event Community",
      number_of_users: "800+",
    },
    {
      name: "AUAA",
      image: Banner2,
      des: "Post a question you are looking for an answer to here. Please note that all questions must have factual answers. We do not allow opinion questions.",
      number_of_users: "700+",
    },
    {
      name: "HotQA Blog",
      image: Banner3,
      des: "Stay up to date with the latest stories and commentary.",
      number_of_users: "780+",
    },
  ];

  const [challenge, setChallenge] = useState<any[]>([]);
  const [groups, setGroups] = useState<typeof datGroup>(datGroup);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    fetch("https://api.hotqa.io/open-challenges", { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setChallenge(response.data ?? []);
        }
      })
      .catch();

    // fetch("https://api.hotqa.io/open-groups", { method: "GET" })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((response) => {
    //     if (Array.isArray(response.data) && response.data.length > 0) {
    //       setGroups(response.data);
    //     }
    //   })
    //   .catch();
  }, []);

  const partners = new Array(16).fill({ img: "", link: undefined });
  partners[0] = { img: PartnersImg[0], link: "https://blckchn.agency/" };
  partners[1] = { img: PartnersImg[1], link: "https://oxalus.io/" };
  partners[2] = { img: PartnersImg[2], link: "https://acestarter.finance/" };

  return (
    <React.Fragment>
      <section className="w-full container mx-auto relative z-10">
        <div className="w-full pt-[74px] flex items-center justify-center flex-col px-4 md:px-0">
          <img src={PlayToLearn} alt="play to learn" className="object-contain w-full max-w-[826px] hidden md:block" />
          <img
            src={PlayToLearnMobile}
            alt="play to learn"
            className="object-contain w-full max-w-[826px] block md:hidden"
          />
          <img src={PlayToLearn2} alt="play to learn 2" className="object-contain w-full max-w-[863px]" />
        </div>
      </section>
      <section
        className="w-full relative hidden items-center justify-center -mt-[250px] lg:flex"
        // style={{
        //   backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/bg-galaxy2.png'})`,
        //   backgroundRepeat:"no-repeat",
        //   backgroundPosition :'center'
        // }}
      >
        <img src={BGGalaxy} alt="galaxy" className="object-contain w-full" />
        <div className="flex flex-col items-center  top-[23.9%] z-10 absolute">
          <h2 className="text-[#AF70FF] font-semibold text-7xl leading-tight cursor-default">
            The Universe of Knowledge
          </h2>
          <p className="text-white mt-6 cursor-default">It's truly more than just a trivia game!</p>
          <div className="flex flex-row justify-center items-center space-x-5 mt-6 ">
            <a
              href="https://play.google.com/store/apps/details?id=com.hotqa.io"
              target="_black"
              onClick={() => {
                sendEventGoogle("Install App", { platform: "Android" });
              }}
            >
              <img src={GooglePlay} alt="Google Play" className="w-[180px] h-[54px] object-contain" />
            </a>
            <a
              href="itms-apps://itunes.apple.com/us/app/hotqa/id1610442730?mt=8"
              target="_black"
              onClick={() => {
                sendEventGoogle("Install App", { platform: "IOS" });
              }}
            >
              <img src={AppStore} alt="App Store" className="w-[180px] h-[54px] object-contain" />
            </a>
          </div>
          <Animated />
          {/* <div className="flex flex-row  space-x-[4.5rem]">
            {itemGalaxy.map((item) => {
              return (
                <div key={`item-${item.label}`} className="text-center mt-[60px]">
                  <p className="text-center text-white cursor-default">{item.label}</p>
                  <img className="mt-10" src={item.thumb} alt={item.label} />
                </div>
              );
            })}
          </div> */}
        </div>
      </section>

      <section
        className="flex lg:hidden -mt-[250px] pt-[250px]"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/bg-galaxy2.png"})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div className="container">
          <h2 className="text-[#AF70FF] font-semibold text-[32px] lg:text-7xl leading-tight cursor-default text-center">
            The Universe of Knowledge
          </h2>
          <p className="text-white mt-6 cursor-default text-center">It's truly more than just a trivia game!</p>

         <div className="items-center justify-center flex overflow-hidden">
           <Animated />
         </div>
        </div>
      </section>
      <section className="pt-24 container mx-auto flex flex-col items-center">
        <h2 className="text-[#3E008C] font-semibold text-[32px] lg:text-7xl leading-tight cursor-default">Ongoing Challenges</h2>
        <p className="text-[#5A606D] mt-6 cursor-default">It's truly more than just a trivia game!</p>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[30px] mt-9 md:mt-12 lg:mt-20 px-4 lg:px-0">
          {challenge.slice(0, 4).map((item) => {
            return (
              <a
                key={`challenge-${item.id}`}
                href={`http://app.hotqa.io/challenge/${item.id}`}
                target={"_blank"}
                className="rounded-[20px] flex flex-col overflow-hidden transform hover:scale-110 cursor-pointer transition bg-[#EBEBEB]"
                rel="noreferrer"
              >
                <img
                  src={item.image && item.image.length > 0 ? item.image : ImgChallenge}
                  alt="challenge"
                  className="rounded-t-[20px] overflow-hidden h-[196px] object-cover"
                />
                <div className="p-4 flex flex-col justify-between">
                  <h3 className="text-xs font-semibold text-black leading-5">{item.name}</h3>
                  <p
                    className="text-[10px] font-medium text-black text-opacity-50 mt-2 max-h-8 overflow-hidden"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  ></p>
                  <div className="flex flex-row items-center justify-between mt-2 ">
                    <div className="inline-flex flex-row items-center space-x-2">
                      {item.setting.host && (
                        <>
                          <img src={item.setting.host?.logo} className="w-8 h-8 object-contain" alt="Avatar" />
                          <p className="text-sm font-medium text-black">{item.setting.host?.name}</p>
                        </>
                      )}
                    </div>
                    <div className="inline-flex flex-row items-center bg-[#EEEEEE] rounded-3xl space-x-2 py-[3px] px-2">
                      <img src={Coin} alt="Coin" className="w-6 h-6" />
                      <p className="text-[10px] font-medium text-[#3A3A3A] text-opacity-50 leading-5">
                        {item.max_reward}
                        {item.setting.currency_code}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>

        <div className="mt-16 flex flex-col px-4">
          <div className="flex items-center justify-center">
            <NavLink to={"/challenge"} reloadDocument={true}>
              <button
                // href="https://bit.ly/HotQA-Challenges"
                // target={"_blank"}
                onClick={() => {
                  sendEventGoogle("Create your Challenge");
                }}
                className={
                  "bg-[#662DDF] text-[#EBEBF0] inline-flex flex-row items-center justify-center text-xl font-semibold rounded-full px-6 py-2 hover:py-[10px] hover:bg-[#00C58D] transition-all duration-300 ease-in-out"
                }
              >
                <span className="text-[#EBEBF0] text-xl font-semibold pr-2 inline-block">
                  {"Create your Challenge"}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_804_30493)">
                    <path
                      d="M13.2969 4.14062L21.5469 12.0156C21.7812 12.25 21.9219 12.5312 21.9219 12.8594C21.9219 13.1406 21.7812 13.4219 21.5469 13.6562L13.2969 21.5312C12.875 21.9531 12.125 21.9531 11.7031 21.4844C11.2812 21.0625 11.2812 20.3125 11.75 19.8906L17.9844 13.9844H2.04688C1.39062 13.9844 0.921875 13.4688 0.921875 12.8594C0.921875 12.2031 1.39062 11.7344 2.04688 11.7344H17.9844L11.75 5.78125C11.2812 5.35938 11.2812 4.60938 11.7031 4.1875C12.125 3.71875 12.8281 3.71875 13.2969 4.14062Z"
                      fill="#EBEBF0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_804_30493">
                      <rect width="24" height="24" fill="white" transform="translate(0.125 0.8125)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </NavLink>
          </div>

          <p className="text-[#5A606D] mt-4 text-center">to incentivize your audience and boost your brand awareness</p>
        </div>
      </section>
      <section className="pt-24 container mx-auto flex flex-col items-center">
        <h2 className="text-[#3E008C] font-semibold text-[32px] lg:text-7xl leading-tight cursor-default">Socials</h2>
        <p className="text-[#5A606D] mt-6 cursor-default">
          Where people regularly interact on the basis of mutual
          <br />
          expectations and share a common identity
        </p>
        <div className="grid grid-cols-1  md:grid-cols-3 ld:grid-cols-3 gap-[30px] mt-9 md:mt-12 lg:mt-20 px-4 lg:px-0">
          {groups.slice(0, 3).map((item) => {
            return (
              <div className="bg-[#E6E6E6] rounded-[20px] p-4 flex flex-col overflow-hidden transform transition  ">
                <img
                  src={item.image}
                  alt="challenge"
                  className="rounded-[10px] overflow-hidden max-h-[194px] object-cover"
                />
                <div className="mt-4 flex flex-row items-center gap-x-4">
                  <img
                    src={item.image}
                    alt="hotqa"
                    className="w-[52px] h-[52px] object-contain rounded-full overflow-hidden"
                  />
                  <span className="font-semibold text-xl text-[#6C6C70]">{item.name}</span>
                </div>
                <button
                  onClick={() => {
                    setShow(true);
                  }}
                  className="flex flex-col h-16 overflow-hidden"
                >
                  <p className="text-[#646464] mt-4 hover:underline transition-all duration-300 ease-in-out text-left">
                    {item.des}
                  </p>
                </button>

                <p className="text-[#8D8D8D] mt-4">{`${item.number_of_users} members`}</p>
                <div className=" flex items-end justify-center">
                  <button
                    onClick={() => {
                      setShow(true);
                    }}
                    className="font-semibold text-sm text-white bg-[#662DDF] rounded-full text-center min-h-[41px] flex justify-center items-center mt-4 hover:bg-[#00C58D] transition-all duration-300 ease-in-out px-6 py-2"
                  >
                    Join Group
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-16 flex flex-col pb-16">
          <ButtonPopupApp
            content="Own your Community"
            iconRight={
              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_804_30493)">
                  <path
                    d="M13.2969 4.14062L21.5469 12.0156C21.7812 12.25 21.9219 12.5312 21.9219 12.8594C21.9219 13.1406 21.7812 13.4219 21.5469 13.6562L13.2969 21.5312C12.875 21.9531 12.125 21.9531 11.7031 21.4844C11.2812 21.0625 11.2812 20.3125 11.75 19.8906L17.9844 13.9844H2.04688C1.39062 13.9844 0.921875 13.4688 0.921875 12.8594C0.921875 12.2031 1.39062 11.7344 2.04688 11.7344H17.9844L11.75 5.78125C11.2812 5.35938 11.2812 4.60938 11.7031 4.1875C12.125 3.71875 12.8281 3.71875 13.2969 4.14062Z"
                    fill="#EBEBF0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_804_30493">
                    <rect width="24" height="24" fill="white" transform="translate(0.125 0.8125)" />
                  </clipPath>
                </defs>
              </svg>
            }
          />
        </div>
      </section>
      <section
        className="mx-auto w-full max-w-[969px] bg-white bg-opacity-60 rounded-[30px] p-4 min-h-[367px] mt-14"
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
      >
        <h2 className="text-[#3E008C] font-semibold text-[32px] lg:text-7xl leading-tight text-center">Partners</h2>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-x-[30px] lg:gap-y-[21px] p-6 md:p-8 lg:px-16 lg:py-10">
          {partners.slice(0, 16).map((item, index) => {
            return (
              <div className="bg-white shadow-xl h-[69px] rounded-[10px] flex items-center justify-center p-4">
                {item.link ? (
                  <a className="overflow-hidden block" href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.img} alt={`Partner-${index}`} className="object-cover max-w-full max-h-full" />
                  </a>
                ) : undefined}
              </div>
            );
          })}
        </div>
      </section>
      <PopupApp
        show={show}
        onClose={() => {
          setShow(false);
        }}
        closeOutsize
      />
    </React.Fragment>
  );
}
