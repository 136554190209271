import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { ButtonPopupApp } from "../component/button-popup-app";
import { useState } from "react";
import burger from "../assets/burger-menu.svg";

export default function HeaderLayout() {
  const [active, setActive] = useState<boolean>(false);

  const menu = [
    { label: "Home", link: "/", target: "_self" },
    { label: "How to Play", link: "/how-to-play", target: "_self" },
    { label: "Whitepaper", link: "https://hotqa.gitbook.io/hotqa-whitepaper", target: "_blank" },
    { label: "Linktree", link: "https://linktr.ee/HotQA_Official", target: "_blank" },
    { label: "News", link: "https://hub.hotqa.io", target: "_blank" },
    { label: "Contact", link: "/contact", target: "_self" },
  ];

  return (
    <header className="fixed w-full bg-white bg-opacity-100 lg:bg-opacity-50  z-50 ">
      <div className="container mx-auto flex items-center justify-between px-4 lg:px-0 py-4 lg:pt-7 lg:pb-2 relative">
        <Link to={"/"} className="logo block max-w-[142px] w-full" reloadDocument>
          <img src={Logo} alt="hotqa" className="object-contain" />
        </Link>

        <nav className="py-2.5 flex-col font-medium lg:flex-row hidden lg:inline-flex space-x-[30px]">
          {menu.map((item, index) => {
            return (
              <NavLink
                key={`nav${index}-${item.label}`}
                to={item.link}
                target={item.target}
                className={({ isActive }) => {
                  return `block text-base ${isActive ? "text-[#662DDF] font-extrabold" : "text-[#8E8E93] font-medium"}`;
                }}
                style={{ fontFamily: "Nunito" }}
              >
                {item.label}
              </NavLink>
            );
          })}
        </nav>

        <ButtonPopupApp
          overClass="hidden lg:inline-block bg-[#662DDF] text-white font-semibold text-2xl px-6 py-2 rounded-full whitespace-nowrap"
          content="Download App"
        />

        <button
          onClick={() => {
            setActive(!active);
          }}
          className={`w-8 h-8 p-1 rounded-md block lg:hidden ${active ? "bg-slate-200" : "bg-white"}`}
        >
          <img src={burger} alt="Navigation" />
        </button>
      </div>
      <nav
        id="sidebar-menu"
        className={`overflow-hidden lg:hidden inline-flex gap-x-[35px] items-center justify-center flex-col z-10 left-0 right-0 border-t-2 p-4 shadow-slate-200 shadow-lg absolute top-full bg-white ${
          active ? "top-full opacity-1 " : "-top-[1000px] opacity-0"
        } transition-all duration-600`}
      >
        {menu.map((item, index) => {
          return (
            <NavLink
              key={`nav${index}-${item.label}`}
              to={item.link}
              target={item.target}
              className={({ isActive }) => {
                return `block border-b py-2 w-full text-base ${
                  isActive ? "text-[#662DDF] font-extrabold" : "text-[#8E8E93] font-medium"
                }`;
              }}
              style={{ fontFamily: "Nunito" }}
              onClick={() => {
                setActive(false);
              }}
            >
              {item.label}
            </NavLink>
          );
        })}

        <ButtonPopupApp
          overClass="inline-block bg-[#662DDF] text-white font-semibold text-2xl px-6 py-2 rounded-full whitespace-nowrap mt-4"
          content="Download App"
        />
      </nav>
    </header>
  );
}
