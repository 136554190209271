import React, { useState } from "react";
import { PopupApp } from "./popup-app";

export function ButtonPopupApp(props: { content: string; iconLeft?: any; iconRight?: any; overClass?: string }) {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-center">
      <button
        type="button"
        className={
          props.overClass
            ? props.overClass
            : "bg-[#662DDF] text-[#EBEBF0] inline-flex flex-row items-center justify-center text-xl font-semibold rounded-full px-6 py-2 hover:py-[10px] hover:bg-[#00C58D] transition-all duration-300 ease-in-out"
        }
        onClick={() => {
          setShow(true);
        }}
      >
        {props.iconLeft}
        <span className="text-[#EBEBF0] text-xl font-semibold pr-2 inline-block">{props.content}</span>
        {props.iconRight}
      </button>

      <PopupApp
        show={show}
        onClose={() => {
          setShow(false);
        }}
        closeOutsize
      />
    </div>
  );
}
